<!-- Lesson started -->
<div id="videoDiv" style="height:100vh;">
    
</div>

<div style="display:none;" *ngIf="lessonStatus == 'STARTED'">
    <div simpleCountdown class="countdown" [dateTo]="lessonEndTime/1000" (finish)="timerFinished()"
    ></div>
</div>

<div class="controllers" *ngIf="lessonStatus == 'STARTED'">
    <button (click)="endCall();" class="controllerBtn">End</button>
    <button (click)="executeCommand('toggleShareScreen')" class="controllerBtn">Screen Share</button>
    <button (click)="toggleCamera();" class="controllerBtn">Change Camera</button>
    <button (click)="executeCommand('toggleAudio')" class="controllerBtn" *ngIf="!isAudioMuted">Mute Audio</button>
    <button (click)="executeCommand('toggleAudio')" class="controllerBtn" *ngIf="isAudioMuted">UnMute Audio</button>
    <button (click)="openWhieBoard()" class="controllerBtn">White Board</button>
    <button (click)="toggleDictionary()" class="controllerBtn">Dictionary</button>
    <button *ngif="hideVirtualBackgroundBtn == false" (click)="toggleVirtualBackground()" class="controllerBtn">Virtual Background</button>
</div>

<!-- Lesson started -->

<!-- Yet To Start -->

<div *ngIf="lessonStatus == 'YET_TO_START'" class="status-text-style">
    <div class="centerd">
        <h1 style="color:#47defd">Session Starts in </h1>
        <div simpleCountdown class="countdown" [dateTo]="lessonStartTime/1000" (finish)="timerFinished()"
        [endMessage]="'Session loading....'"
        [styles]="'font-size: 40px;
        color: #fc7f38;
        background-color: #272727;
        padding: 10px 4px;
        font-weight: bold;'"
        ></div>
    </div>
</div>

<!-- Yet To Start -->

<!-- Expired -->

<div *ngIf="lessonStatus == 'EXPIRED'" class="status-text-style">
    <div class="centerd">
        <h1 style="color:#47defd;">Session Expired</h1>
    </div>
    
</div>

<!-- Invalid Lesson -->

<div *ngIf="lessonStatus == 'INVALID'" class="status-text-style">
    <div class="centerd">
        <h1 style="color:#47defd;">Invalid Lesson</h1>
    </div>
    
</div>

<div *ngIf="isDictionary" class="dictionary" cdkDrag>
    <span style="position: absolute;right: 20px; cursor: pointer;z-index: 11; top:10px;"
        (click)="toggleDictionary();">x</span>
    <span style="position: absolute;right: 30px; cursor: pointer;z-index: 11; top:12px;" id="dictionary" cdkDragHandle>
        <mat-icon aria-hidden="false" aria-label="Example home icon" style="font-size: 15px;">open_with</mat-icon></span>
    <!-- <iframe src="https://dictionary-ui.meteortutors.com/" title="Dictionary"
        style="width:100%; height:100%;border-radius: 15px; border-width: 0;"></iframe> -->
        <div style="width:100%; height:100%;border-radius: 15px; border-width: 0;">
            <app-lesson-dictionary></app-lesson-dictionary>
        </div>
</div>