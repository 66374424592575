<div style="width: 100%; height: 100vh;" id="wt-container"></div>

<div *ngIf="lessonStatus == 'EXPIRED'" class="status-text-style">
    <div class="centerd">
        <h1 style="color:#47defd;">Session Expired</h1>
    </div>
</div>

<div *ngIf="lessonStatus == 'YET_TO_START'" class="status-text-style">
    <div class="centerd">
        <h1 style="color:#47defd;">Session Not Started Yet</h1>
    </div>
</div>

<div *ngIf="lessonStatus == 'INVALID'" class="status-text-style">
    <div class="centerd">
        <h1 style="color:#47defd;">Invalid Lesson</h1>
    </div>
</div>