<app-header [currentPage]="'student-view-invitations'"></app-header>

<section id="features" class=" section offset-header" style="background: #232323;">
    <div class="container">
        <h3 class="title  "><b>Invitation Details</b></h3>
            <small><b><span style="color: #fc7f38; font-size: 16px;">Important: </span>Invitation details: Before your Meeting, use this link 
              <a href="https://tokbox.com/developer/tools/precall/">(https://tokbox.com/developer/tools/precall/)</a> to check that your computer, tablet or smartphone is setup correctly.</b></small>
        <div class="row">
            <div class="item col-lg-12 col-md-12 col-12 mt-5" style="background: #46dffc;border-radius: 20px;margin-bottom: 20px;">
                <div class="" style="padding: 20px 50px;">
                        <form role="form">

                            <div class="row">
                                <h3 class="" style="color: #fc7f38; font-weight: bold;">Invitations</h3>

                                <div id="dataGridContainer" class="col-md-12">
                                    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onSortData($event)" matSortActive="lessInvitation.id"
                                      matSortDirection="asc" matSortDisableClear class="mat-elevation-z8" style="width: 100%;">
                                  
                                      <ng-container matColumnDef="lessInvitation.lesson.title">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sesson Title</th>
                                        <td mat-cell *matCellDef="let row"> {{row.lessonTitle}} </td>
                                      </ng-container>
                                  
                                      <ng-container matColumnDef="lessInvitation.lesson.startTime">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sesson Start Time</th>
                                        <td mat-cell *matCellDef="let row"> {{row.lessonStartTimeDisp}} </td>
                                      </ng-container>
                                  
                                      <ng-container matColumnDef="lessInvitation.lesson.lessonStatus">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                                        <td mat-cell *matCellDef="let row"> {{row.lessonStatus}} </td>
                                      </ng-container>

                                      <ng-container matColumnDef="actionButtonColumn">
                                        <th mat-header-cell *matHeaderCellDef> Action </th>
                                        <td mat-cell *matCellDef="let row">
                                            <button class="btn btn-outline-success btn-xs" (click)="openSessionPage(row.invitationId)">Open Lesson Window</button>
                                        </td>
                                      </ng-container>
                                  
                                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>
                                    <div *ngIf="dataSource.data.length == 0">
                                      No Items to show
                                    </div>
                                    <mat-paginator [length]="totalRowsCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"
                                      (page)="onPaginateChange($event)">
                                    </mat-paginator>
                                  </div>
                            </div>
                            <!-- <ul class="list-inline pull-right">
                                <li></li>
                            </ul> -->
                        </form>
                    
                </div><!--//content-->               
            </div><!--//item-->
        </div><!--//row-->
    </div><!--//container-->
</section><!--//features-->


<app-footer></app-footer>