import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgxSpinnerModule } from 'ngx-bootstrap-spinner';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { StudentRegistrationComponent } from './student-registration/student-registration.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { PositioningService } from 'ngx-bootstrap/positioning';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TutorSubscriptionDetailsComponent } from './tutor-subscription-details/tutor-subscription-details.component';
import { StripePaymentDialogComponent } from './stripe-payment-dialog/stripe-payment-dialog.component';
import { RegistrationSuccessComponent } from './registration-success/registration-success.component';
import { TutorRegistrationComponent } from './tutor-registration/tutor-registration.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { StudentHomeComponent } from './student-home/student-home.component';
import { TutorHomeComponent } from './tutor-home/tutor-home.component';
import { TutorInvitationMgtComponent } from './tutor-invitation-mgt/tutor-invitation-mgt.component';
import { TutorInvitationMgtDialogComponent } from './tutor-invitation-mgt-dialog/tutor-invitation-mgt-dialog.component';
import { EmailInputComponent } from './email-input/email-input.component';
import { TagInputModule } from 'ngx-chips';
import { MatTableModule } from '@angular/material/table' 
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { DeleteConfirmationDialogComponent } from './delete-confirmation-dialog/delete-confirmation-dialog.component';
import { LessonComponent } from './lesson/lesson.component';
import { NgxSimpleCountdownModule } from 'ngx-simple-countdown';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { StudentViewInvitationsComponent } from './student-view-invitations/student-view-invitations.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ManageUsersComponent } from './admin/manage-users/manage-users.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { AdminHeaderComponent } from './admin/admin-header/admin-header.component';
import { NgxMatDateAdapter, NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { WhiteboardComponent } from './whiteboard/whiteboard.component';
import { MatIconModule } from '@angular/material/icon';
import { LessonDictionaryComponent } from './lesson-dictionary/lesson-dictionary.component';



@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    StudentRegistrationComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    TutorSubscriptionDetailsComponent,
    StripePaymentDialogComponent,
    RegistrationSuccessComponent,
    TutorRegistrationComponent,
    ContactUsComponent,
    StudentHomeComponent,
    TutorHomeComponent,
    TutorInvitationMgtComponent,
    TutorInvitationMgtDialogComponent,
    EmailInputComponent,
    DeleteConfirmationDialogComponent,
LessonComponent,
SessionExpiredComponent,
StudentViewInvitationsComponent,
ErrorPageComponent,
ManageUsersComponent,
EmailVerificationComponent,
AdminHeaderComponent,
WhiteboardComponent,
LessonDictionaryComponent,
  ],
  imports: [
    ToastrModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    TagInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    NgxSimpleCountdownModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule, 
    MatInputModule,
    NgxMatNativeDateModule,
    DragDropModule,
    MatIconModule
  ],
  providers: [BsModalService, PositioningService, ComponentLoaderFactory],
  bootstrap: [AppComponent]
})
export class AppModule { }
