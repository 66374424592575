import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})
export class LandingComponent implements AfterViewInit {
  @ViewChild('landingVideo') landingVideo: ElementRef;

  videoNumber:number;
  videoUrl: string;
  constructor(private spinner: NgxSpinnerService) {
    this.videoNumber = this.randomIntFromInterval(1,5);
    const videoURls = [
			"https://meteor.file.core.windows.net/meteortest101/landing/fireball/1.mp4",
			"https://meteor.file.core.windows.net/meteortest101/landing/fireball/2.mp4",
			"https://meteor.file.core.windows.net/meteortest101/landing/fireball/3.mp4",
			"https://meteor.file.core.windows.net/meteortest101/landing/fireball/4.mp4",
			"https://meteor.file.core.windows.net/meteortest101/landing/fireball/5.mp4"
		]
    this.videoUrl = "https://files.meteortutors.com/AzureService/file?fileUrl="+ videoURls[this.videoNumber - 1];
  }
  ngAfterViewInit(): void {
    this.spinner.show();
    this.landingVideo.nativeElement.addEventListener(
      'loadedmetadata',
      () => {
        this.spinner.hide();
      },
      false
    );
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }

  randomIntFromInterval(min:number,max:number){
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
}
