import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LOGOUT_URL } from '../constants/ApiURLConst';
import { ApiResponse } from '../model/apiresponse';
import { LoginUserInfo } from '../model/LoginUserInfo';
import { RestApiService } from '../service/restapi.service';
import { AppUtil } from '../util/apputil';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @Input() currentPage: string;

  public loginUserInfo: LoginUserInfo;
  public navBarCollapse:string='out';

  public appPages = [];

  private ALL_POST_LOGIN_PAGES = {
    TUTOR: [
      {
        title: 'Invitation Management',
        page: 'tutor-invitation-mgt',
        url: '/tutor-invitation-mgt',
      },
      {
        title: 'Subscription Details',
        page: 'tutor-subscription-details',
        url: '/tutor-subscription-details',
      },
    ],
    STUDENT: [
      {
        title: 'View Invitations',
        page: 'student-view-invitations',
        url: '/student-view-invitations',
      },
    ],
    ADMIN: [
      {
        title: 'Home',
        page: 'student-page',
        url: '/student-page',
      },
    ],
  };

  constructor(
    private appUtil: AppUtil,
    private router: Router,
    private restApi: RestApiService
  ) {
    this.loginUserInfo = appUtil.getLoginInfo();
    if (this.loginUserInfo != null) {
      this.appPages = this.ALL_POST_LOGIN_PAGES[this.loginUserInfo.role];
      console.log(this.appPages);
    }
  }

  ngOnInit(): void {
    this.loginUserInfo = this.appUtil.getLoginInfo();
  }

  handleLogoutClick(): void {
    localStorage.removeItem('loginUserInfo');
    this.restApi.getItem(LOGOUT_URL, (response: ApiResponse) => {
      this.router.navigate(['login']);
      console.log('Logout success!');
    });
  }

  toggleMenu(): void{
    if(this.navBarCollapse == 'out'){
      this.navBarCollapse = 'in';
    }else{
      this.navBarCollapse = 'out';
    }

  }
}
