import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LOGIN_URL } from '../constants/ApiURLConst';
import { ApiResponse } from '../model/apiresponse';
import { LoginUserInfo } from '../model/LoginUserInfo';
import { RestApiService } from '../service/restapi.service';
import { UIUtil } from '../util/ui-util';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  public loginCredentialInput: any = {};

  constructor(
    private router: Router,
    private restApi: RestApiService,
    private uiUtil: UIUtil
  ) {}

  ngOnInit(): void {
    localStorage.removeItem('loginUserInfo');
  }

  handleLoginButtonClick() {
    this.submitLoginInfo();
  }

  submitLoginInfo() {
    this.restApi.postItem(
      LOGIN_URL,
      JSON.stringify(this.loginCredentialInput),
      (response: ApiResponse) => {
        var loginUserInfo: LoginUserInfo = response.responseObject;
        localStorage.setItem('loginUserInfo', JSON.stringify(loginUserInfo));
        this.redirectToHomePage(loginUserInfo.role);
      },
      (error: ApiResponse) => {
        this.uiUtil.showErrorMessage(error.messageList[0].message);
        //this.validationError = error.messageList[0].message;
      }
    );
  }

  redirectToHomePage(role: string): void {
    if (role == 'STUDENT') {
      this.router.navigate(['student-page']).then(() => {
        window.location.reload();
      });
    } else if (role == 'TUTOR') {
      this.router.navigate(['tutor-page']).then(() => {
        window.location.reload();
      });
    } else if (role == 'ADMIN') {
      this.router.navigateByUrl('admin-page');
    }
  }
}
