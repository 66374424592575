import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageUsersComponent } from './admin/manage-users/manage-users.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { LandingComponent } from './landing/landing.component';
import { LessonComponent } from './lesson/lesson.component';
import { LoginComponent } from './login/login.component';
import { RegistrationSuccessComponent } from './registration-success/registration-success.component';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { StudentHomeComponent } from './student-home/student-home.component';
import { StudentRegistrationComponent } from './student-registration/student-registration.component';
import { StudentViewInvitationsComponent } from './student-view-invitations/student-view-invitations.component';
import { TutorHomeComponent } from './tutor-home/tutor-home.component';
import { TutorInvitationMgtComponent } from './tutor-invitation-mgt/tutor-invitation-mgt.component';
import { TutorRegistrationComponent } from './tutor-registration/tutor-registration.component';
import { TutorSubscriptionDetailsComponent } from './tutor-subscription-details/tutor-subscription-details.component';
import { WhiteboardComponent } from './whiteboard/whiteboard.component';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: 'student-registration',
    component: StudentRegistrationComponent,
  },
  {
    path: 'tutor-subscription-details',
    component: TutorSubscriptionDetailsComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'tutor-registration',
    component: TutorRegistrationComponent,
  },
  {
    path: 'registration-success',
    component: RegistrationSuccessComponent,
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
  },
  {
    path: 'tutor-invitation-mgt',
    component: TutorInvitationMgtComponent,
  },
  {
    path: 'student-page',
    component: StudentHomeComponent,
  },
  {
    path: 'tutor-page',
    component: TutorHomeComponent,
  },
  {
    path: 'lesson',
    component: LessonComponent,
  },
  {
    path: "student-view-invitations",
    component: StudentViewInvitationsComponent,
  },
  {
    path: 'session-expired',
    component: SessionExpiredComponent,
  },
  {
    path: 'error-page',
    component: ErrorPageComponent,
  },{
    path: 'admin-page',
    component: ManageUsersComponent,
  },
  {
    path: 'email-verification-page',
    component: EmailVerificationComponent
  },
  {
    path: 'whiteboard',
    component: WhiteboardComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
