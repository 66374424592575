<app-admin-header></app-admin-header>



<section id="features" class=" section offset-header" style="background: #232323;">
  <div class="container">
    <h3 class="title  "><b>Manage Users</b></h3>
    <div class="row">
      <div class="item col-lg-12 col-md-12 col-12 mt-5" style="background: #46dffc;border-radius: 20px;margin-bottom: 20px;">
        <div class="" style="padding: 20px 50px;">
          <div id="dataGridContainer" class="col-md-12">
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onSortData($event)" matSortActive="id"
              matSortDirection="asc" matSortDisableClear class="mat-elevation-z8" style="width: 100%;">
          
              <ng-container matColumnDef="emailId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Id</th>
                <td mat-cell *matCellDef="let row"> {{row.emailId}} </td>
              </ng-container>
          
              <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
                <td mat-cell *matCellDef="let row"> {{row.role}} </td>
              </ng-container>

              <ng-container matColumnDef="accountStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Status</th>
                <td mat-cell *matCellDef="let row"> {{row.accountStatus}} </td>
              </ng-container>

              <ng-container matColumnDef="lastLogin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Last login</th>
                <td mat-cell *matCellDef="let row"> {{row.lastLogin}} </td>
              </ng-container>

              <ng-container matColumnDef="lastLoginTimeZone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Last login timezone</th>
                <td mat-cell *matCellDef="let row"> {{row.lastLoginTimeZone}} </td>
              </ng-container>

              
              <ng-container matColumnDef="accountCreated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Created</th>
                <td mat-cell *matCellDef="let row"> {{row.accountCreated}} </td>
              </ng-container>
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div *ngIf="dataSource.data.length == 0">
              No Items to show
            </div>
            <mat-paginator [length]="totalRowsCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"
              (page)="onPaginateChange($event)">
            </mat-paginator>
          </div>

        </div>
      </div>

    </div>
  </div>


</section>



