import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';

@Component({
  selector: 'app-student-home',
  templateUrl: './student-home.component.html',
  styleUrls: ['./student-home.component.css']
})
export class StudentHomeComponent implements AfterViewInit {

  @ViewChild('landingVideo') landingVideo: ElementRef;

  constructor(private spinner: NgxSpinnerService) {}

  ngAfterViewInit(): void {
    this.spinner.show();
    this.landingVideo.nativeElement.addEventListener(
      'loadedmetadata',
      () => {
        this.spinner.hide();
      },
      false
    );
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }

}
