<section id="features" class=" section" style="background: #333333;width: 100%;bottom: 0;">
    <div class="container">
      <div class="row">
        <div class="content col-md-12 col-12 order-md-12 text-center">
          <h2 class="" style="padding: 30px 0;color: #fc7f38; font-weight: bold;">Follow us on social media</h2>
          <div>
            <!-- <a href="https://www.facebook.com/Meteortutors"><img alt="" height="50px" src="assets/images/social/fb.png"/></a> -->
                <a href="https://www.instagram.com/fireball.video/"><img alt="" height="50px" src="assets/images/social/ig.png"/></a>
                <a href="https://www.linkedin.com/company/86257045/admin/"><img alt="" height="50px" src="assets/images/social/li.png"/></a>
                <!-- <a href="https://www.youtube.com/c/MusicalProdigy"><img alt="" height="50px" src="assets/images/social/yt.png"/></a> -->
                <!-- <a href="https://www.pinterest.co.uk/meteortutors"><img alt="" height="50px" src="assets/images/social/pi.png"/></a> -->
                <a href="https://twitter.com/fireballvideo"><img alt="" height="50px" src="assets/images/social/tw.png"/></a>
          </div>
          <div style="margin-top:10px;">
            <b class="copyright mt-4" style="font-size: 18px;color: #a0a0a0;"> &copy; 2022 Fireball Video - All Rights Reserved </b>
          </div>
          
        </div>
        <!--//content-->
  
      </div>
      <!--//row-->
      </div>
  </section>
  <script>
    googleTranslateElementInit();
  </script>