<div class="text-center" *ngIf="!isPaid">
    <h3>Order Confirmation</h3>
    <h5>You’re paying for {{paymentIntentRequest.planName}}</h5>
    <h3>Total Amount : {{currencySymbol}} {{amount}}</h3>
    <img src="../../assets/images/cc.png" height="30" style="margin-bottom: 10px;">
    <div #cardElement class="sr-input sr-card-element"></div>
    <p style="color: red;">{{cardErrors}}</p>
    <button 
    class="btn btn-primary btn-lg next-step"
                                style="background: #fe7f38; color: #fff;border: none;margin-bottom: 15px;"
    (click)="makePayment()">Confirm</button>
</div>
<div class="text-center" *ngIf="isPaid">
    
    <h4>Payment was successfull</h4>
    
</div>