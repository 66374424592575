<header id="top" class="header fixed-top">
    <div class="">
        <nav id="main-nav" class="main-nav navbar-light navbar-expand-md" role="navigation">

            <button class="navbar-toggler hidden-md hidden-lg" type="button" data-toggle="collapse" (click)="toggleMenu()"
                data-target="#navbar-collapse" aria-controls="navbar-collapse" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon" style="color: #35e3fc;"></span>
            </button>
            <h1 class="logo pull-right">
              <a class="scrollto" href="/">
                  <img id="logo-image" class="logo-image" src="assets/images/logo/fireball-strapline.png" style="width:465px;" alt="Logo">
              </a>
          </h1>
            <div [ngClass]="navBarCollapse" class="navbar-collapse collapse justify-content-end" id="navbar-collapse">

                <!--//logo-->
                <ul class="nav navbar-nav" *ngIf="loginUserInfo == null">
                    <li class="nav-item"><a class="nav-link scrollto" [routerLink]="['/student-registration']">GUESTS
                            <i style="margin-left: 15px;"> |</i></a></li>
                    <li class="nav-item"><a class="nav-link scrollto" [routerLink]="['/tutor-registration']">HOSTS <i
                                style="margin-left: 15px;"> |</i></a></li>
                    <li class="nav-item"><a class="nav-link scrollto" [routerLink]="['/tutor-registration']">REGISTER <i
                                style="margin-left: 15px;"> |</i></a></li>
                    <li class="nav-item last"><a class="nav-link scrollto" [routerLink]="['/login']">LOGIN <i
                                style="margin-left: 15px;"> |</i></a></li>
                    <li class="nav-item"><a class="nav-link scrollto" [routerLink]="['/contact-us']">CONTACT</a></li>
                </ul>
                <!--//nav-->
                <ul class="nav navbar-nav" *ngIf="loginUserInfo != null"> 
                    <li class="nav-item"><a class="nav-link scrollto" href="/">HOME <i
                            style="margin-left: 15px;"> |</i></a></li>

                    <li *ngFor="let p of appPages; let i = index" class="nav-item"><a class="nav-link scrollto"
                            [class.active]="currentPage == p.page" [routerLink]="[p.url]"
                            skipLocationChange>{{p.title}}<i style="margin-left: 15px;"> |</i></a></li>
                            <li class="nav-item"><a class="nav-link scrollto" [routerLink]="['/login']" (click)="handleLogoutClick()">LOGOUT <i
                                style="margin-left: 15px;"> |</i></a></li>
                </ul>
            </div>
            <!--//navabr-collapse-->
        </nav>
        <!--//main-nav-->

    </div>
</header>
<!--//header-->
