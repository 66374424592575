<app-header [currentPage]="'contact-us'"></app-header>

<section id="features" class=" section offset-header" style="background: #232323;">
    <div class="container">
        <h3 class="title  "><b>How to Contact Fireball Video.</b></h3>
           <p> <b>The form below is the best way to get in touch:</b></p>
        <div class="row">
            <div class="item col-lg-12 col-md-12 col-12 mt-5" style="background: #46dffc;border-radius: 20px;margin-bottom: 20px;">
                <div class="" style="padding: 20px 50px;">
                        
                            
                              
                            <div class="row">
                                <div class="col-md-6">
                                  <input type="text" class="form-control form-control-lg" [(ngModel)]="contactRequest.senderName"
                                  placeholder="Name">
                                  <div style="color:red">{{this.errorMessageMap.get('senderName')}}</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                  <input type="text" class="form-control form-control-lg" [(ngModel)]="contactRequest.senderEmail"
                                  placeholder="EMAIL ADDRESS">
                                  <div style="color:red">{{this.errorMessageMap.get('senderEmail')}}</div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-6" >
                                  <input type="text" class="form-control form-control-lg" [(ngModel)]="contactRequest.subject"
                                  placeholder="Subject">
                                  <div style="color:red">{{this.errorMessageMap.get('subject')}}</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                  <textarea rows="5" class="form-control form-control-lg" [(ngModel)]="contactRequest.content"
                                  placeholder=""></textarea>
                                  <div style="color:red">{{this.errorMessageMap.get('content')}}</div>
                                </div>
                              </div>
                           <div class="form-group">
                                <div class="form-check">
                                  
                                  <button type="button" class="btn btn-primary btn-lg next-step pull-right" (click)="handleSubmitButtonClick()"
                                  style="background: #fe7f38; color: #fff;border: none;margin-bottom: 15px;">SEND</button>
                                </div>
                              </div>
                            <!-- <ul class="list-inline pull-right">
                                <li></li>
                            </ul> -->
                        
                    
                </div><!--//content-->               
            </div><!--//item-->
            <p><b>You can also use our email address: <span style="color: #fc7f38;">
              <a href="mailto:admin@fireball.video">
                admin@fireball.video
              </a>
            </span></b></p>
        </div><!--//row-->
        
        
        
    </div><!--//container-->
</section><!--//features-->

<app-footer></app-footer>