<app-header [currentPage]="'tutor-subscription-details'"></app-header>


<section id="features" class=" section offset-header" style="background: #232323;">
    <div class="container">
        <h3 class="title  "><b>Subscription Details</b></h3>
            <small><b><span style="color: #fc7f38; font-size: 16px;">Important: </span>As a Tutor, you can buy a 30 Day Plan for £4.99. This will enable you to securely invite your own registered guests to your lessons with all the benefits that Fireball Video has to offer. For further information please message us on our Contact Page.</b></small>
        <div class="row">
            <div class="item col-lg-12 col-md-12 col-12 mt-5" style="background: #46dffc;border-radius: 20px;margin-bottom: 20px;">
                <div class="" style="padding: 20px 50px;">
                        
                            <div class="row">
                                <div class="col-md-6">
                                    <b style=" font-size: 16px;">Subscription end date: 
                                        <span style="color: #fc7f38;" *ngIf="subscriptionInfo.subscriptionEndDate != null">{{subscriptionInfo.subscriptionEndDate}}</span>
                                        <span style="color: red;" *ngIf="subscriptionInfo.subscriptionEndDate == null">No Subscription Available</span></b>
                                </div>
                                <div class="col-md-6">
                                    <button type="button" (click)="openStripePaymentDialog()"
                                    class="btn btn-primary btn-lg next-step pull-right" style="background: #fe7f38; color: #fff;border: none;margin-bottom: 15px;">Buy Subscription</button>
                                </div>
                                
                            </div>
                            <div class="row">
                                <h3 class="" style="color: #fc7f38; font-weight: bold;">Payment History</h3>


                                <div id="dataGridContainer" class="col-md-12">
                                    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onSortData($event)" matSortActive="id"
                                      matSortDirection="desc" matSortDisableClear class="mat-elevation-z8" style="width: 100%;">
                                  
                                      <ng-container matColumnDef="planName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Name</th>
                                        <td mat-cell *matCellDef="let row"> {{row.planName}} </td>
                                      </ng-container>
                                  
                                      <ng-container matColumnDef="createdDate">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Date</th>
                                        <td mat-cell *matCellDef="let row"> {{row.paymentDate}} </td>
                                      </ng-container>
                                  
                                      <ng-container matColumnDef="planPrice">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
                                        <td mat-cell *matCellDef="let row"> {{currencySymbol}} {{row.paymentAmount}} </td>
                                      </ng-container>

                                      <ng-container matColumnDef="paymentHistory.transactionId">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Id</th>
                                        <td mat-cell *matCellDef="let row"> {{row.transactionId}}</td>
                                      </ng-container>
                                  
                                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>
                                    <div *ngIf="dataSource.data.length == 0">
                                      No Items to show
                                    </div>
                                    <mat-paginator [length]="totalRowsCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"
                                      (page)="onPaginateChange($event)">
                                    </mat-paginator>
                                  </div>
                            </div>
                        
                    
                </div><!--//content-->               
            </div><!--//item-->
        </div><!--//row-->
    </div><!--//container-->
</section><!--//features-->



<app-footer></app-footer>

