import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AbstractDataGridSupport } from '../base/AbstractDataGridSupport';
import {
  GET_AVAILABLE_TUTOR_SUBSCRIPTION_PLAN_URL,
  GET_TUTOR_SUBSCRIPTION_HISTORY_URL,
  GET_TUTOR_SUBSCRIPTION_INFO_URL,
} from '../constants/ApiURLConst';
import { ApiResponse } from '../model/apiresponse';
import { DataGridParams } from '../model/datagrid-model';
import { RestApiService } from '../service/restapi.service';
import { StripePaymentDialogComponent } from '../stripe-payment-dialog/stripe-payment-dialog.component';
import { UIUtil } from '../util/ui-util';

@Component({
  selector: 'app-tutor-subscription-details',
  templateUrl: './tutor-subscription-details.component.html',
  styleUrls: ['./tutor-subscription-details.component.css'],
})
export class TutorSubscriptionDetailsComponent extends AbstractDataGridSupport implements OnInit {
  public subscriptionInfo: any = {};
  public availableSubscriptionPlans: Array<any> = new Array<any>();
  public currencySymbol: string = '£'; //TODO
  constructor(
    private modalService: BsModalService,
    private restApi: RestApiService,
    private uiUtil: UIUtil
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeUIObjects()
  }

  getIDataGridParams(): DataGridParams {
    let dataGridParams: DataGridParams = {
      displayedColumns: [
        'planName',
        'createdDate',
        'planPrice',
        'paymentHistory.transactionId'
      ],
      serverApi: () => {
        this.loadSubscriptionHistory();
      },
    };
    return dataGridParams;
  }

  initializeUIObjects(datagridLoadReqd?: boolean){
    this.loadAvailablePlans();
    this.loadCurrentSubscriptionPlan();
    if(datagridLoadReqd == true){
      this.loadSubscriptionHistory();
    }
  }

  loadAvailablePlans(): void {
    this.restApi.getItem(
      GET_AVAILABLE_TUTOR_SUBSCRIPTION_PLAN_URL,
      (response: ApiResponse) => {
        this.availableSubscriptionPlans = response.responseObject;
        if (
          this.availableSubscriptionPlans == null ||
          this.availableSubscriptionPlans.length == 0
        ) {
          this.uiUtil.showErrorMessage(
            'There is no subscription plan configured in the backend'
          );
        }
      }
    );
  }

  loadCurrentSubscriptionPlan(): void {
    this.restApi.getItem(
      GET_TUTOR_SUBSCRIPTION_INFO_URL,
      (response: ApiResponse) => {
        this.subscriptionInfo = response.responseObject;
      }
    );
  }

  loadSubscriptionHistory(): void {
    this.restApi.postItem(
      GET_TUTOR_SUBSCRIPTION_HISTORY_URL,
      JSON.stringify(this.getDataGridRequest()),
      (response: ApiResponse) => {
        this.assignDataSourceParams(response.responseObject);
      }
    );
  }

  openStripePaymentDialog(): void {
    if (
      this.availableSubscriptionPlans == null ||
      this.availableSubscriptionPlans.length == 0
    ) {
      this.uiUtil.showErrorMessage(
        'There is no subscription plan configured in the backend'
      );
      return;
    }
    var options: ModalOptions<StripePaymentDialogComponent> =
      new ModalOptions<StripePaymentDialogComponent>();
    //options.ignoreBackdropClick = true;
    options.class = 'modal-dialog-centered';
    options.initialState = {
      paymentIntentRequest: {
        planName: this.availableSubscriptionPlans[0].planName,
        serviceType:
          this.subscriptionInfo.currentPlanId == null
            ? 'TUTOR_SUBSCRIPTION_CREATION'
            : 'TUTOR_SUBSCRIPTION_RENEWAL',
        planId: this.availableSubscriptionPlans[0].planId,
      },
    };

    var modelRef: BsModalRef = this.modalService.show(
      StripePaymentDialogComponent,
      options
    );

    modelRef.onHide.pipe().subscribe(() => {
      if (
        modelRef.content != null && modelRef.content.isPaid
      ) {
        this.initializeUIObjects(true);
      }
    });
  }
}
